<template>
  <div class="flex items-center justify-between px-4 py-6">
    <SiteLogo class="h-[36px]" dark />

    <Button
      class="lg:hidden"
      icon="pi pi-arrow-left"
      link
      @click="emit('closeSidebar')"
    />
  </div>

  <div class="duration-300 ease-linear flex flex-1 flex-col no-scrollbar overflow-y-auto">
    <nav class="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
      <div v-for="menu in navItems" class="mb-5">
        <h5 class="font-bold mb-2 px-5 text-gray-500 uppercase">{{ menu.label }}</h5>
        <PanelMenu
          :model="menu.items"
          class="border-none bg-surface-900 dark:bg-surface-900"
        >
        </PanelMenu>
      </div>

    </nav>
  </div>

  <div class="drawer-side">
    <div class="flex flex-col min-h-full p-4 text-white">
      <div class="flex-none py-2 text-center text-xs">
        <div class="space-x-3">
          <NuxtLink
            :to="{
              name: 'docs-privacy-policy'
            }"
          >
            Privacy Policy
          </NuxtLink>

          <NuxtLink
            :to="{
              name: 'docs-terms-of-use'
            }"
          >
            Terms of Service
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MenuItem } from 'primevue/menuitem'
import type { RouteLocationRaw } from 'vue-router'

const { currentCompany } = useCompany()
const emit = defineEmits<{
  closeSidebar: []
}>()
const { logout } = useAuth()

const navItems = computed<MenuItem[]>(() => {
  if (!currentCompany.value) return []

  const menu = [
    {
      label: 'Dashboard',
      icon: 'pi pi-home',
      command: () => goTo({
        name: 'companyId',
        params: { companyId: currentCompany.value?.id },
      }),
    },
    {
      label: 'Menu',
      items: [
        {
          label: 'Calendar',
          icon: 'pi pi-calendar',
          command: () => goTo({
            name: 'companyId-crm-calendar',
            params: {companyId: currentCompany.value?.id},
          }),
        },
        {
          label: 'Board',
          icon: 'pi pi-objects-column',
          command: () => goTo({
            name: 'companyId-crm-board',
            params: {companyId: currentCompany.value?.id},
          }),
        },
        {
          label: 'Leads',
          icon: 'pi pi-user',
          command: () => goTo({
            name: 'companyId-crm-leads',
            params: {companyId: currentCompany.value?.id},
          }),
        },
        {
          label: 'Phone Numbers',
          icon: 'pi pi-phone',
          command: () => goTo({
            name: 'companyId-crm-phone-numbers',
            params: {companyId: currentCompany.value?.id},
          }),
        },
        {
          label: 'Campaigns',
          icon: 'pi pi-compass',
          command: () => goTo({
            name: 'companyId-crm-campaigns',
            params: {companyId: currentCompany.value?.id},
          }),
        },
        {
          label: 'Calls',
          icon: 'pi pi-megaphone',
          command: () => goTo({
            name: 'companyId-crm-calls',
            params: {companyId: currentCompany.value?.id},
          }),
        },
        {
          label: 'Conversations',
          icon: 'pi pi-comments',
          command: () => goTo({
            name: 'companyId-crm-conversations',
            params: {companyId: currentCompany.value?.id},
          }),
        },
      ]
    }
  ]

  if (currentCompany.value?.id === 29 || location.host === 'localhost:3000') {
    menu[1].items.push(
      {
        label: 'Scrapper',
        icon: 'pi pi-sparkles',
        items: [
          {
            label: 'Customers',
            command: () => goTo({
              name: 'companyId-crm-scrapper-customers',
              params: { companyId: currentCompany.value?.id },
            })
          },
          {
            label: 'Proxies',
            command: () => goTo({
              name: 'companyId-crm-scrapper-proxies',
              params: { companyId: currentCompany.value?.id },
            })
          },
          {
            label: 'Angi',
            items: [
              {
                label: 'Credentials',
                command: () => goTo({
                  name: 'companyId-crm-scrapper-angi-credentials',
                  params: { companyId: currentCompany.value?.id },
                })
              },
            ]
          },
          {
            label: 'Networx',
            items: [
              {
                label: 'Credentials',
                command: () => goTo({
                  name: 'companyId-crm-scrapper-networx-credentials',
                  params: { companyId: currentCompany.value?.id },
                })
              }
            ]
          }
        ]
      }
    )
  }

  menu.push(
    {
      label: 'AI',
      items: [
        {
          label: 'Prompts',
          icon: 'pi pi-comment',
          command: () => goTo({
            name: 'companyId-ai-prompts',
            params: { companyId: currentCompany.value?.id },
          })
        },
        {
          label: 'Claude',
          items: [
            {
              label: 'Requests',
              command: () => goTo({
                name: 'companyId-ai-claude-requests',
                params: { companyId: currentCompany.value?.id },
              })
            }
          ]
        },
      ]
    }
  )
  menu.push(
    {
      label: 'Account',
      items: [
        {
          label: 'Your account',
          icon: 'pi pi-user',
          command: () => goTo({ name: 'account' }),
        },
        {
          label: 'Switch company',
          icon: 'pi pi-home',
          command: () => goTo({ name: 'index' }),
        },
        {
          label: 'Change password',
          icon: 'pi pi-key',
          command: () => goTo({ name: 'account-password' }),
        },
        {
          label: 'Logout',
          icon: 'pi pi-sign-out',
          command: () => logout(),
        },
      ]
    }
  )

  menu.push(
    {
      label: 'Setup',
      items: [
        {
          label: 'Configuration',
          icon: 'pi pi-cog',
          command: () => goTo({
            name: 'companyId-configuration',
            params: { companyId: currentCompany.value?.id },
          }),
        }
      ]
    }
  )

  return menu
})

const goTo = (params: RouteLocationRaw) => {
  navigateTo(params)
  emit('closeSidebar')
}
</script>

<style lang="scss" scoped>
:deep(.p-panelmenu-panel) {
  @apply bg-transparent !border-none !shadow-none;
}

:deep(.p-panelmenu-header-link) {
  @apply text-surface-0
}

:deep(.p-panelmenu-item-label) {
  @apply text-surface-0
}
</style>
